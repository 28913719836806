import React from 'react';
import workspace from '../img/workspace.jpg';
import workspace2 from '../img/workspace2.jpg';
import research from '../img/research.svg'
import planning from '../img/planning.svg'
import development from '../img/development.svg'
import realize from '../img/realize.svg'

const AboutDesign = () => (
  <div className="box-container">
    <img src={workspace2} alt="workspace" />
    <div className="about-design-list">
      <h2 className="l-title">Our Approach</h2>
      <ul>
        <li>
          <img src={research} alt="research" />
          <h3 className="a-title">
            01.
            <br />
            RESEARCH
          </h3>
          <p className="l-list-2">深入了解委託案的特性，包含同產業中市場現有的各種設計調查，以及商品特性調查與相關文化研究。</p>
          <br />
          <p className="l-list-e">Having a deep understanding of both the client's needs & the charactor of the project,including market surveys, product charactors and culture-related research. </p>
        </li>
        <li>
          <img src={planning} alt="planning" />
          <h3 className="a-title">
            02.
            <br />
            PLANNING
          </h3>
          <p className="l-list-2">結合品牌特色、商品特色、設計市場調查後，確認消費族群的各項資訊，譬如年齡、職業、銷售管道等，再提出適合該委託案的視覺以及文案企劃。</p>
          <br />
          <p className="l-list-e">Find the target through all the research and propose an appropriate visual and context proposal. </p>
        </li>
        <li>
          <img src={development} alt="development" />
          <h3 className="a-title">
            03.
            <br />
            DEVELOPMENT
          </h3>
          <p className="l-list-2">根據與委託者所共同擬定的企劃，並依販賣環境或方式，在預定的成本下的考量，發展設計提案，賦予品牌新生命與情感價值。</p>
          <br/>
          <p className="l-list-e">Finalize the proposal with client's needs. Develop the project according to the budget. Excute the proposal under realist considerations and apply a new image and value on it.</p>
        </li>
        <li>
          <img src={realize} alt="realize" />
          <h3 className="a-title">
            04.
            <br />
            REALIZE
          </h3>
          <p className="l-list-2">每個好的設計稿都需要有好的製作設定與監督，才能成為最棒商業使用的設計，因此在材質選用、印刷方式、施工零件等等，每個細節都由我們親自把關。</p>
          <br />
          <p className="l-list-e">Pre-production and administration are well-prepared for best excution.We pay great attentions to all the details included materials,printing process and small components of constuction.</p>
        </li>
      </ul>
      <div className="clearfix"></div>
    </div>
    <div className="l-info">
      <h2 className="l-title">NEWS</h2>
      <dl>
        <dt>2020.</dt>
        <dd>
          Idn 雜誌 26.1 櫥窗營銷設計 / 收錄
          <p className="l-list-e">Idn 26.1（International Designer Network magazine）Windows Display + Visual Merchandising </p>
        </dd>
      </dl>
      <dl>
        <dt>2018.</dt>
        <dd>
          ACTA亞洲創意對話Creative Talk in Asia 4 / 收錄
          <p className="l-list-e">Creative Talk in Asia 4 </p>
        </dd>
      </dl>
      <dl>
        <dt>2017.</dt>
        <dd>
          第13屆 APD亞太設計年鑑 / 入選
          <p className="l-list-e">Asia-Pacific Design No. 13</p>
        </dd>
      </dl>
        <dl>
        <dt>2015.</dt>
        <dd>
          第11屆 APD亞太設計年鑑 / 入選
          <p className="l-list-e">Asia-Pacific Design No. 11</p>
        </dd>
      </dl>
      <div className="clearfix"></div>
    </div>
  </div>
);

const Profile = () => (
  <div className="box-container">
    <img src={workspace} alt="workspace" />
    <div className="about-list">
      <h2 className="l-title">Who We Are</h2>
      <p className="l-list">- Nomadhouse Studio -
        <br />視覺行動主義者 / 提倡者。
        <br />設計該具有一定的幽默，
        <br />而幽默是深度的觀察結果，
        <br />親切地傳達給對方。
        <br />希望持續而漸進的以人味設計推動社區與環境的潜移默化。
        <br />
        <br />visual and cultural activism/advocate
        <br />
        <br />Nomad + house / No + mad + house /
        <br />No + madhouse / madhouse + ON
        <br />
        <br />Nomadhouse is a Taiwan-based studio that creates design solutions for clients in publishing, art, fashion, music, lifestyle, and for cultural institutions.
        <br />Our office is located in Taichung City,a cozy area in the middle of Taiwan with lots of green and parks.
      </p>
    </div>
    <div className="l-info">
      <h2 className="l-title">Company Profile</h2>
      <dl>
        <dt>Company</dt>
        <dd>Nomadhouse Studio</dd>
      </dl>
      <dl>
        <dt>E-mail</dt>
        <dd>nomadhouse96@gmail.com</dd>
      </dl>
      <dl>
        <dt>Established</dt>
        <dd>Jan. 2012</dd>
      </dl>
      <dl>
        <dt>Services</dt>
        <dd>CIS視覺識別系統 / 平面視覺設計 / 商品包裝設計 / 櫥窗陳列 / 展演空間企劃
        <br />
        <p className="l-list-e">brand & identity, graphic design, signage design, packaging design , print & communication, packaging design, exhibition,  window display</p></dd>
      </dl>
      <dl>
        <dt>Designer, Director | Co-Founder</dt>
        <dd>
          YUCHIEN CHANG
          <br />1985年生，台中豐原人。畢業於國立成功大學 工業設計學系。
          <br />
          <p className="l-list-e">Born 1985 in Taichung. 2007 graduated NCKU Industrial Design.</p>
        </dd>
      </dl>
      <dl>
        <dt>Designer, Director | Co-Founder</dt>
        <dd>
          CHINGWEN CHENG
          <br />1983年生，彰化人。畢業於國立台北教育大學 藝術與藝術教育學系。
          <br />
          <p className="l-list-e">Born 1983 in Changhua. 2005 graduated NTTC Art and Art Education.</p>
        </dd>
      </dl>
      <div className="clearfix"></div>
    </div>
  </div>
)

const About = (props) => {
  const { activeTag } = props;

  return (activeTag === 0
    ? <Profile />
    : <AboutDesign />)
};

export default About;
